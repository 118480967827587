<template>
    <section class="zc-crear-nuevo mt-4 ml-4">
        <div class="row">
            <div class="col-12">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                    <el-breadcrumb-item :to="{ name: 'zonas.listado' }">
                        Zonas comunes
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>Nueva zona</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div v-loading="loading" class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7">
                <ValidationObserver ref="formCrearZona" tag="div">
                    <div class="row">
                        <p class="col-12 f-500 f-20 mb-3 mt-5">Nueva zona común</p>
                        <div class="col-12 col-md-6 mb-3">
                            <p class="f-12 pl-2 label-inputs">Nombre</p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:70" name="nombre">
                                <el-input v-model="zona.nombre" placeholder="Nombre de la zona" show-word-limit maxlength="70" size="small" />
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 mb-3">
                            <ValidationProvider v-slot="{errors}" tag="div" class="w-100" rules="required|max:750" name="descripción">
                                <p class="f-12 pl-2 label-inputs">Descripción</p>
                                <el-input v-model="zona.descripcion" placeholder="Descripción de la zona" show-word-limit maxlength="750" type="textarea" :autosize="{ minRows: 4, maxRows: 4}" />
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 mb-3">
                            <ValidationProvider v-slot="{errors}" tag="div" class="w-100" rules="required" name="recomendaciones">
                                <p class="f-12 pl-2 label-inputs">Recomendaciones de uso</p>
                                <el-input v-model="zona.reglas" placeholder="Recomendaciones" type="textarea" :autosize="{ minRows: 9, maxRows: 7}" />
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 mb-3">
                            <ValidationProvider v-slot="{errors}" tag="div" class="w-100" rules="required|numeric|min_value:1|max_value:65535" name="aforo máximo">
                                <p class="f-12 pl-2 label-inputs">Aforo máximo</p>
                                <el-input v-model="zona.aforo_maximo" placeholder="Número de personas" :controls="false" min="1" class="w-90 text-justify" size="small" />
                                <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                                    <template #content>
                                        <p class="f-14">Establece el número máximo de asistentes al mismo tiempo en la zona común.</p>
                                    </template>
                                    <i class="icon-help-circle-outline f-20" />
                                </el-tooltip>
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </ValidationObserver>
                <ValidationObserver ref="formPago">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <div class="d-middle">
                                <p class="f-15">Requiere pago</p>
                                <ValidationProvider tag="div">
                                    <el-switch v-model="zona.pago_tipo" :active-value="1" :inactive-value="0" active-color="#00D9B5" inactive-color="#E6E6E6" class="mx-2" />
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div v-show="zona.pago_tipo == '1'" class="row">
                        <div class="col-12 col-md-6 mb-3">
                            <ValidationProvider v-slot="{errors}" tag="div" class="w-100" rules="required|numeric|min_value:1|max_value:16777215" name="valor del bloque">
                                <p class="f-12 pl-2 label-inputs">Valor del bloque de reserva</p>
                                <el-input v-model="zona.pago_valor" placeholder="Valor" class="w-90" size="small" />
                                <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                                    <template #content>
                                        <p class="f-14">Indique el valor de uso de esta zona común por bloque de tiempo</p>
                                    </template>
                                    <i class="icon-help-circle-outline f-20" />
                                </el-tooltip>
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <ValidationProvider v-slot="{errors}" tag="div" class="w-100" rules="required|numeric|min_value:1|max_value:255" name="horas">
                                <p class="f-12 pl-2 label-inputs">Tiempo antes de cancelarse la reserva por falta de pago</p>
                                <el-input v-model="zona.pago_horas" placeholder="Valor en horas" class="w-90" size="small" />
                                <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                                    <template #content>
                                        <p class="f-14">Establece el número máximo de horas que tiene el residente para pagar la reservación antes de que se cancele falta de pago.</p>
                                    </template>
                                    <i class="icon-help-circle-outline f-20" />
                                </el-tooltip>
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 col-sm-auto">
                            <button class="btn btn-general h-32px f-300 px-5 f-14 w-100" @click="guardar">
                                Guardar
                            </button>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </section>
</template>
<script>
import Zonas from '~/services/zonas'
export default {
    data(){
        return{
            value: '',
            inputValorBloque: '',
            valueSwitch: false,
            loading : false,
            zona: {
                nombre: '',
                descripcion: '',
                reglas: '',
                aforo_maximo: null,
                pago_tipo: null,
                pago_valor: null,
                pago_horas: null,
            }
        }
    },
    methods:{
        guardarContinuar(){
            this.$router.push({ name: 'zonas.guardar.zona' })
        },
        async guardar(){
            try {

                let validateCrear = await this.$refs.formCrearZona.validate()
                if(!validateCrear) return this.notificacion('','Campos incompletos','warning')

                if(this.zona.pago_tipo){
                    let validatePago = await this.$refs.formPago.validate()
                    if(!validatePago) return this.notificacion('','Por favor complete los campos de pago','warning')
                }else{
                    // para que en la base de datos se cree con el valor DEFAULT
                    delete this.zona['pago_tipo']
                    delete this.zona['pago_valor']
                    delete this.zona['pago_horas']
                }

                this.zona.aforo_maximo = Number(this.zona.aforo_maximo)

                this.loading = true
                const {data} = await Zonas.guardar(this.zona)
                this.loading = false
                this.notificacion('','Zona creada correctamente','success')
                if(data.success){
                    this.$router.push({ name: 'zonas.guardar.zona', params:{id_zona : data.data.id} })
                }
            } catch (e){
                this.loading = false
                this.errorCatch(e)
            }
        },

    }
}
</script>
<style lang="scss" scoped>
.zc-crear-nuevo{
    .icon-help-circle-outline{
        position: relative;
        top: 3px;
        cursor: pointer;
    }
}
</style>