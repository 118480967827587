import axios from 'axios'

const API = '/zonas'

const Zonas = {
    listar(params){
        return axios(`${API}`,{params})
    },    
    guardar(payload){
        return axios.post(`${API}/guardar`,payload)
    },
    editar(id_zona,payload){
        return axios.put(`${API}/${id_zona}/editar`,payload)
    },    
    obtenerZona(id_zona){
        return axios.get(`${API}/${id_zona}/detalle`)
    },
    datosAdicionalZona(id_zona){
        return axios.get(`${API}/${id_zona}/datosAdicionales`)
    },
    editarDatosAdicionales(id_zona,payload){
        return axios.put(`${API}/${id_zona}/editar/datos-adicionales`,payload)
    }, 
    horarioZona(id_zona){
        return axios.get(`${API}/${id_zona}/horarios`)
    },    
    listarImagenes(id_zona){
        return axios.get(`${API}/imagenes/${id_zona}`)
    },
    subirImagen(payload){
        return axios.post(`${API}/imagenes`,payload)
    },    
    eliminarImagen(params){
        return axios.delete(`${API}/imagenes/eliminar`,{params})
    }, 
    guardarDiaDeshabilitado(payload){
        return axios.post(`${API}/deshabilitar-dias`,payload)
    },
    habilitarDia(id_dia_deshabilitado){
        return axios.delete(`${API}/habilitar-dias/${id_dia_deshabilitado}`)
    },
    eliminarHorario(id_horario){
        return axios.delete(`${API}/eliminar-horario/${id_horario}`)
    },
    duplicarHorario(payload){
        return axios.post(`${API}/duplicar-horario`,payload)
    },  
    inactivarDia(payload){
        return axios.put(`${API}/inactivar-dia`,payload)
    },  
    guardarHorario(payload){
        return axios.post(`${API}/habilitar-horario`,payload)
    },
    horarios(id_zona,fecha){
        return axios.get(`${API}/horarios-reserva/${id_zona}/${fecha}`)
    },
    eliminarZonaComun(params){
        return axios.delete(`${API}/eliminar`,params)
    },
    cambiarEstadoZonaComun(params){
        return axios.put(`${API}/cambiar-estado`,params)
    },
    getRecomendacionesUso(idZone){
        return axios.get(`${API}/${idZone}/recomendaciones-uso`)
    },
    putReglas(idZone, payload){
        return axios.put(`${API}/${idZone}/recomendaciones-uso/actualizar`, payload)
    },
    listarZonasComunes(){
        return axios.get(`${API}/comunes/listar`)
    }, 
}

export default Zonas
